



















































































































































































import { defineComponent, onMounted, ref, onBeforeUnmount } from '@vue/composition-api';
import BreadCrumb from "~/almarwan/components/Breadcrumb.vue";
import Icon from "~/almarwan/components/Icon.vue";
import { SfLoader } from '@storefront-ui/vue';
import { useUser } from '~/modules/customer/composables/useUser';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';
import { SfInput, SfModal, SfCheckbox } from '@storefront-ui/vue';
import { walletCardsFormFields } from './types';
import StripeComponent from '~/modules/checkout/pages/Checkout/StripeComponent.vue';
import { eventBus } from "@/almarwan/helpers/EventBus";
import type { AvailablePaymentMethod } from '~/modules/GraphQL/types';
import usePaymentProvider from '~/modules/checkout/composables/usePaymentProvider';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import MyFinancing from "~/modules/customer/pages/MyAccount/MyFinancing.vue";
import Percentage from "~/modules/checkout/pages/Checkout/PercentagePayment.vue";
import {useRoute, useRouter, watch} from "@nuxtjs/composition-api";
import {useUiNotification} from "~/composables";

export default defineComponent({
  name: "WalletTab",
  components: {
    Percentage,
    MyFinancing,
    Icon,
    SfLoader,
    BreadCrumb,
    SfInput,
    SfModal,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,
    StripeComponent,
    SkeletonLoader
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      activeRow: '',
    }
  },
  emits: ['submit'],
  setup(_props, { emit }) {
    const { customerCards, customerPaymentId, customerSetDefaultCard, customerDeleteCard, customerAddCard, financeRequests } = useUser();
    const breadcrumbs = [{
      link: '/customer/my-wallet',
      text: 'Account'
    }];
    const { send: sendNotification } = useUiNotification();
    const selectFinanceType = ref(0)
    const { save } = usePaymentProvider();
    const cardId = ref('')
    const currentTab = ref('cards')
    const route = useRoute()
    const router = useRouter()
    const loadingFinance = ref(false)
    const financeData = ref([])
    const filteredApplications = ref([])
    const walletForm = ref<walletCardsFormFields>({
      c_holder_name: '',
      card_number: '',
      card_type: '',
      expiry_date: '',
      default_card: false,
    });
    const cardList = ref([])
    const accordions = ref([])
    const showNewCardForm = ref(false);
    const cardsLoading = ref(false);
    const latestDraft = ref(null);
    const isFinanceDraft = ref(false);
    const loadCards = async() => {
      cardsLoading.value = true;
      const custCards = await customerCards();
      if(custCards['payment'].cards !==null){
        cardList.value = custCards['payment'].cards;
      }
      cardsLoading.value = false;
    }
    const setDefaultCard = async(card, event) => {
      if(event){
        const setDefault = await customerSetDefaultCard(card.id);
        await loadCards();
        notifyWalletUpdate()
      }
    }
    const addCard = async(pmId) => {
      if(pmId){
        const addNew = await customerAddCard(pmId);
        showNewCardForm.value = false;
        await loadCards();
        notifyWalletUpdate();
      }
    }
    const removeCard = async(card) => {
      if(card.id){
        const removeCard = await customerDeleteCard(card.id);
        await loadCards();
        notifyWalletUpdate()
      }
    }
    const showStripeComponent = () => {
      showNewCardForm.value = true;
    }
    const paymentMethods = ref<AvailablePaymentMethod[]>([]);

    const accordionData = () => {
      if (financeData.value.length > 0) {
        accordions.value = financeData.value.map((app) => {
          // @ts-ignore
          return { id: app?.id, isOpen: false, child: false, payment: false }
        })
      }
    }

    const selectType = (type) => {
      selectFinanceType.value = type
      if (type === 0) {
        return filteredApplications.value = financeData.value
      }
      filteredApplications.value = financeData.value.filter((app) => app.type === type)
    }
    const loadFinanceRequests = async() => {
      financeData.value = await financeRequests(null)
      if (financeData.value) {
        filteredApplications.value = financeData.value
        loadingFinance.value = true
        accordionData()
      }
    }
    const checkFinanceDrafts = async() => {
      let financeArr = [];
      financeArr.push({'status' : 9})
      let draftList = await financeRequests(financeArr);
      if(draftList?.length > 0) {
        latestDraft.value = draftList[0];
        isFinanceDraft.value = true
      }
    }
    const goToFinancePage = () => {
      router.push('/credit-application');
    }
    const toggleSidebar = () => {
      let sidebar = document.querySelector('.account__sidebar');
      if (sidebar.classList.contains('active')) {
        sidebar.classList.remove('active')
      } else {
        sidebar.classList.add('active')
      }
    }
    const notifyWalletUpdate = () => {
      sendNotification({
        id: Symbol('user_updated'),
        message: 'Your wallet information has been successfully updated.',
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'Customer Wallet',
      });
    }

    watch(() => currentTab.value, () => {
      if (currentTab.value == 'financing') {
        loadFinanceRequests();
      }
    });
    onMounted(async()=> {
      const custPaymentID = await customerPaymentId();
      loadCards()
      eventBus.$on('paymentMethodId', (val) => {
        addCard(val)
      });
      checkFinanceDrafts()
      if(route.value.query?.financing ){
        currentTab.value = 'financing'
      }
    })

    onBeforeUnmount(async() => {
      eventBus.$off('paymentMethodId');
    })


    return {
      router,
      filteredApplications,
      selectFinanceType,
      currentTab,
      loadingFinance,
      selectType,
      accordions,
      financeData,
      breadcrumbs,
      walletForm,
      cardList,
      setDefaultCard,
      showStripeComponent,
      showNewCardForm,
      removeCard,
      cardsLoading,
      isFinanceDraft,
      goToFinancePage,
      latestDraft,
      toggleSidebar
    }
  },
})
