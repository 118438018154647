































































































































































































import {defineComponent, onMounted, ref, toRef, useContext} from "@nuxtjs/composition-api";
import useUser from "~/modules/customer/composables/useUser";
import Icon from "~/almarwan/components/Icon.vue";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import useUiHelpers from "../../../../composables/useUiHelpers";

export default defineComponent({
  name: 'MyFinancing',
  components: {SkeletonLoader, Icon},
  props: {
    financeApplications: {
      type: Array,
      default: []
    },
    accordions: {
      type: [Array, Object],
      required: true
    },
    loadingFinance: {
      type: Boolean,
      default: false
    }
  },
  setup (props, {emit}) {
    const { app } = useContext();
    const { financeApplication, financeInvoice } = useUser();
    const { numberWithCommas } = useUiHelpers()
    const filteredApplications = toRef(props, 'financeApplications')
    const accord = ref(false)
    const btnExpand = ref(false)
    const downloadClicked = ref(false)
    const pdfClicked = ref(false)
    const paidInvoices = ref([])
    const accordion = ref(props.accordions)
    const desktopCheck = app.$device.isDesktop;
    const getDate = (date) => {
      const currentDate = new Date(date);
      return currentDate.toLocaleDateString();
    }
    const openAccordion = (id) => {
      accord.value = !accord.value
      // @ts-ignore
      props.accordions?.find((accord) => {
        if (accord.id === id) {
          accord.isOpen = !accord.isOpen
        }
      })
    }
    const getStatColor = (stat) => {
      if(stat == 'Approved') {
        return "#09B79B";
      } else if (stat == 'pre-approved') {
        return '#53BBFF';
      } else if (stat == 'Denied') {
        return '#E2184A';
      } else if (stat == 'Received') {
        return '#8671FF';
      }else {
        return '#8671FF';
      }
    }
    const financeType = (id) => {
      if (id === 0) {
        return 'General Application'
      } else if (id === 1) {
        return 'Al Marwan'
      } else {
        return 'Bank'
      }
    }
    const showFinanceItems = (id) => {
      // @ts-ignore
      props.accordions?.find((accord) => {
        if (accord.id === id) {
          accord.child = !accord.child
        }
      })
    }
    const showPayment = (id) => {
      // @ts-ignore
      props.accordions?.find((accord) => {
        if (accord.id === id) {
          accord.payment = !accord.payment
        }
      })
    }
    const fetchPdf = async (id) => {
      downloadClicked.value = true
      const app = await financeApplication(id)
      await downloadPdf(app[0].pdf)
    }
    const downloadPdf = async (pdf) => {
      let linkSource = pdf;
      const downloadLink = document.createElement("a");
      const fileName = "finance_Application.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      downloadClicked.value = false
    }
    const printPdf = async (id) => {
      pdfClicked.value = true
      const app = await financeApplication(id)
      if (app) {
        const printJS = require('print-js');
        // @ts-ignore: Unreachable code error
        printJS({printable: app[0].pdf.split(',')[1], type: 'pdf', base64: true})
        pdfClicked.value = false
      }
    }
    const receiptDownload = async (appId, installment) => {
      if (installment.state === 'Paid') {
        let paidInvoices = await financeInvoice(appId)
        if (paidInvoices.length) {
          let currentInvoice = paidInvoices[0]?.order?.invoices?.filter((child) => Number(atob(child.id)) === installment.invoice_id)
           setTimeout(async () => {
             if (currentInvoice?.length && currentInvoice?.pdf) {
               await downloadInstallmentPdf(currentInvoice, installment)
             }
           }, 1000)
        }
      }
    }
    const downloadInstallmentPdf = async (invoice, installment) => {
     if (invoice?.pdf) {
       let linkSource = invoice.pdf;
       const downloadLink = document.createElement("a");
       const fileName = `installment-${installment.due_date}.pdf`;
       downloadLink.href = linkSource;
       downloadLink.download = fileName;
       downloadLink.click();
     }
    }

    const calculateFinanceAmount = (application) => {
      let  amount = application.order.grand_total - ((application.order.grand_total) - (application.order.total_due))
      let decimal
      if (!Number.isInteger(amount)) {
        decimal = amount?.toFixed(2)
      } else {
        decimal = amount
      }
      return numberWithCommas(decimal)
    }
    const getDecimalsTrimmed = (amt) => {
      return amt?.toFixed(2)
    }

    return {
      calculateFinanceAmount,
      numberWithCommas,
      getDate,
      receiptDownload,
      filteredApplications,
      pdfClicked,
      printPdf,
      downloadClicked,
      fetchPdf,
      btnExpand,
      showPayment,
      showFinanceItems,
      accordion,
      financeType,
      getStatColor,
      desktopCheck,
      openAccordion,
      accord,
      getDecimalsTrimmed
    }
  }
})
